.Home {
  max-width: 512px;
}

.App-header {
  display: flex;
  justify-content: space-between;
}

.App-nav-wrapper {
  width: 100%;
  margin-bottom: 48px;
  margin-right: 8px;
}

.App-nav-wappers {
  display: flex;
}

.App-link {
  text-decoration: none;
  transition: 0.3s;
}

.App-title {
  padding: 0;
  margin: 0;
  font-weight: 900;
}

.App-subtitle {
  padding: 0;
  margin: 0;
  font-weight: 700;
}

.App-desc {
  padding-top: 16px;
  padding-bottom: 16px;
}

.App-blink {
  animation: blinker 1s linear infinite;
}

.App-button-link {
  text-decoration: none;
}

.App-button {
  border-radius: 8px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 8px;
}

.App-button:hover {
  border-radius: 8px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.App-button-alt {
  border-radius: 8px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.App-button-alt:hover {
  border-radius: 8px;
  height: 60px;
  margin-right: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.App-button-text {
  text-align: center;
  font-weight: 700;
}

.App-button-text-alt {
  text-align: center;
  font-weight: 700;
}

.App-button-wrapper {
  display: flex;
  flex-direction: column;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.visitors-container {
  margin-top: 16px;
}

.vistors-header {
  font-size: 0.8em;
  margin: 0;
}

.visitors-date {
  font-size: 0.5em;
}

.theme-toggle-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}