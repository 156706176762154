.subtle {
  font-size: small;
}

.subtle:hover {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.category-container {
  font-weight: 700;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.category-text {
  width: 196px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-bracket {
  display: inline-block;
}

.category-colon {
  margin-left: 5px;
}

.App-blogpost-title a {
  font-weight: 700;
  text-decoration: none;
}

.App-blogpost-title a:hover {
  font-weight: 700;
  text-decoration: underline;
}

ul.blog-list {
  padding: 0;
  list-style: none;
}

li.blog-list-item {
  padding: 10px 0;
  transition: background-color 0.3s;
}

h3 {
  font-weight: 700;
  margin-bottom: 0px;
}

.post-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.App-nav {
  display: flex;
  gap: 10px;
}

.App-nav .App-link {
  text-decoration: none;
}

.App-nav .App-nav-selected {
  font-weight: bold;
}

.subscription-button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}