.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination-button {
  border: none;
  border-radius: 4px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 16px;
  width: 24px;
  height: 24px;
}

.pagination-button:disabled {
  cursor: not-allowed;
}
