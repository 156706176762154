.Login {
  max-width: 512px;
}

.Login-subtitle {
  padding: 0;
  margin: 0;
  font-weight: 700;
  color: rgb(87, 158, 64);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  color: rgb(87, 158, 64);
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: rgb(87, 158, 64);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: rgb(87, 158, 64, 0.6);
}